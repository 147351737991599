export const environment = {
  production: false,
  isMainnet: false,
  contracts: {
    gameToken: '0x8A9082b903B69942D468Cd6141c0D01bA2111Ab7',
    lottoToken: '0x8F11E380CA32DfAeA1ABD92eBacbcecEA1e87E6e',
    swapContract: '0x26110fe2C3E39BcBb3B6652b4777C2c16C15a66E',
    lotteryGameFactory: '0xB89F5c72A39E2d56E2e3102363a0f561810b6D84',
    rewardDistribution: '0x7c9471d02b834870bd5f97066e4d9e5a894c913B',
    governanceStrategy: '0xEBB2eA35be6953eFd85C3AE4bC6B89592Ee0D3d0',
    executor: '0x48aAFDF79B876A97f4A0bFcF20f2b7334b0de6A3',
    governance: '0x2bd0Ef5962Af9a328c02bf5d4dca399d9d9d1981',
    lotteryGame: '0x1845BA04d3425d13B4D06d6af1Bccf9DfC193a5d',
    generateBytecode: '0xe607cBcd19764a4386dd2df91E2a128FA1EcfF6d',
    dateTime: '0x48641F5b8e11D931CB2253b9d13518eDBf8061D0',
    router: '0x3426cDa4c1ddBE6Df460DD3783B426211A7CEf37',
    linkToken: '0x0b9d5D9136855f6FEc3c0993feE6E9CE8a297846',
  },
  scanUrl: 'https://testnet.snowtrace.io',
  network: [
    {
      avalanche: {
        walletConnectProjectId: 'b51de7e7a93b21c88f7e07224f3aabf6',
        icon: 'assets/svg/network/avax-logo.svg',
        name: 'Avalanche Fuji Testnet',
        chainId: 43113,
        explorerUrl: 'https://subnets.avax.network',
        rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
        isActive: true
      }
    }
  ],
  pinataJWT: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI5Nzg1YTQ2My1lNWZmLTQ3MTItYjVhMC1iN2VhMTYzOWE2NjgiLCJlbWFpbCI6InR5bGVyQGxvdHRvLmZpbmFuY2UiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MSwiaWQiOiJGUkExIn0seyJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MSwiaWQiOiJOWUMxIn1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiYTNmM2RlYjBjYjE2NTNlZGIyZTQiLCJzY29wZWRLZXlTZWNyZXQiOiJhOGViOTJjYWNkMWViNjkzZGZkNDkwOTcwZTQzOTk3YTM1NDE4MTgzN2I5ZDgyZDkwNDI3MmE0MmNjOGI0YWQyIiwiZXhwIjoxNzU4OTY3MzY3fQ.LUNDzd0o71_gfTjIPZMZAKTMogDShfHvp2zK-Yu_Gbw',
  pinataGateway: 'turquoise-occupational-echidna-241.mypinata.cloud',
};
